// import { useEffect  } from 'react';
import { useContext } from 'react';
import { GoogleLogin,googleLogout } from '@react-oauth/google';
import { getAccessToken } from '../../helpers/services/admin.services'
import { useNavigate  } from "react-router-dom";
import GlobalStateContext from '../../GlobalStateContext';
import  secureLocalStorage  from  "react-secure-storage";
import toast from 'react-hot-toast';

const LoginPage = () =>{

    const navigate = useNavigate();
    const { setRoles } = useContext(GlobalStateContext);

    const handleLoginSuccess = async (credential_response) =>{
        try {
            // console.log("logon api -- ")
            const response = await getAccessToken({
                google_token:credential_response.credential
            })
            // console.log("response login -= ",response.status,response)
            if(response.status == "success"){
                let roles={}
                if(response?.data?.roles){
                    response.data.roles?.map(role=>roles[role]=true)
                }
                setRoles({...roles})
                // await localStorage.setItem("accessToken",response?.data?.token)
                // await localStorage.setItem("roles",JSON.stringify(roles))
                await secureLocalStorage.setItem("accessToken",response?.data?.token)
                await secureLocalStorage.setItem("roles",roles)
                setTimeout(()=>{
                    if(roles?.sales_manager){
                        navigate("/sales")
                    }else if(roles?.food_vp){
                        navigate("/food")
                    }else if(roles.marketing_manager){
                        navigate("/website/blog")
                    }else{
                        navigate("/residents")
                    }
                },1000)
            }else {
                // Handle unsuccessful API response here
                toast.error("You are not authorized")
            }
            // handle redirect
        } catch (error) {
            console.log("Error while login -- ",error)
        }
    }

    return(
        <div className="d-flex align-items-center justify-content-center" style={{height:500}}>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    handleLoginSuccess(credentialResponse)
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
                auto_select={false}
            />
        </div>
    );
}

export default LoginPage;
