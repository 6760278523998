import { useEffect, useState } from 'react';
import toaster from '../../custom-hot-toast/CopyToast';

import '../../../css/onboarding-details-tab.css';

function PropertyTab({ residentInfo, onboardingInfo }) {
    // const [contractDetails, setContractDetails] = useState(null);
    const [basicDetails, setBasicDetails] = useState(null);

    useEffect(() => {
        // if (residentInfo && residentInfo?.contract_details) setContractDetails(residentInfo?.contract_details);
        // if (residentInfo && residentInfo?.basic_details) setBasicDetails(residentInfo?.basic_details);
        if (onboardingInfo && onboardingInfo?.subscription_plan) setBasicDetails(onboardingInfo?.subscription_plan);
    }, [residentInfo, onboardingInfo]);

    const handleCopy = (text, fieldName) => {
        navigator.clipboard.writeText(text);
        toaster(`${fieldName} is copied`);
    };

    return (
        <>
            <div className='row'>
                <div className='col-md-6'>
                    <div className='brd_box_card'>
                        <div className="box_head">
                            <h2> <img src="/images/bank_note_2.svg" className="pr-2" alt="" /> Subscription Plan</h2>
                        </div>
                        <div className="box_contain details_tab">
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Property name</h3>
                                <i>:</i>
                                <span>{basicDetails?.property_name ?? '--'}</span>
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Property ID</h3>
                                <i>:</i>
                                {
                                    onboardingInfo?.property_details?.id ? (
                                        <span
                                            className='cursor-pointor'
                                            onClick={() => handleCopy(onboardingInfo.property_details.id, 'Property ID')}
                                        >
                                            <img src="/images/copy.svg" className="pr-1" alt="" />{onboardingInfo.property_details.id}
                                        </span>
                                    ) : (<span>--</span>)
                                }
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Bed number</h3>
                                <i>:</i>
                                <span>{basicDetails?.beds ?? '--'}</span>
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Room type</h3>
                                <i>:</i>
                                <span>{basicDetails?.room_type?.name ?? '--'}</span>
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Referral code</h3>
                                <i>:</i>
                                {
                                    basicDetails?.referral_code ? (
                                        <span
                                            className='cursor-pointor'
                                            onClick={() => handleCopy(basicDetails?.referral_code, 'Referral code')}
                                        >
                                            <img src="/images/copy.svg" className="pr-1" alt="" />{basicDetails?.referral_code}
                                        </span>
                                    ) : (<span>--</span>)
                                }
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Contract start date</h3>
                                <i>:</i>
                                <span>{basicDetails?.contract_start_date ?? '--'}</span>
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Contract end date</h3>
                                <i>:</i>
                                <span>{basicDetails?.contract_end_date ?? '--'}</span>
                            </div>
                            <div className="d-flex justify-content-between  box_card_data">
                                <h3>Check in date</h3>
                                <i>:</i>
                                <span>{basicDetails?.check_in_date ?? '--'}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-6'>
                    <div className="property_table commen_table">
                        <div className="page-table-box">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Bill Type</th>
                                        <th>Collection Frequency</th>
                                        <th className="text-right">Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(onboardingInfo?.billing_details && onboardingInfo?.billing_details.length > 0) ?
                                        onboardingInfo?.billing_details.map((billing, index) => (
                                            <tr key={index}>
                                                <td>{billing?.payment_plan_name}</td>
                                                <td>{billing?.installment_frequency}</td>
                                                <td className="text-right">{parseInt(billing?.amount)}</td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan={2}>No billing details</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}

export default PropertyTab;