export default {
    ticketStatus: [
        { key: '', label: 'All' },
        { key: 'open_tickets', label: 'Open' },
        { key: 'solved_tickets', label: 'Solved' },
        { key: 'closed_tickets', label: 'Closed' },
        // {key : "in_progress_tickets" , label : "In Progress"},
        // {key : "waiting_for_customer" , label : ""},
        // {key : "on_hold" , label : ""},
        // {key : "solved" , label : ""}
    ]
};

// export default {
//     residentStatus: [
//         { key: '', label: 'All' },
//         { key: 'paid_tab', label: 'Paid' },
//         { key: 'os_tab', label: 'OS' }, // Outstanding
//         { key: 'od_tab', label: 'OD' }, // Overdue
//         // { key: '', label: 'Late fee interest' },
//         { key: 'wifi_disconnected_tab', label: 'Wifi disconnected' },
//         { key: 'services_stopped_tab', label: 'Service stopped' },
//         { key: 'to_be_evicted_tab', label: 'To be evicted' },
//         { key: 'exit_tab', label: 'Exit' },
//         { key: 'absconded_tab', label: 'Absconded' },
//         { key: 'terminated_tab', label: 'Terminated' },
//     ]
// };
