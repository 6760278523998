import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs } from 'react-bootstrap';

// TABS
import ScheduleTab from "../../components/tabs/resident-details/schedule.tab";
import LedgerTab from "../../components/tabs/resident-details/ledger.tab";
import OnBoardingInfoTab from "../../components/tabs/resident-details/onboarding-info.tab";
import ReferAFriendTab from "../../components/tabs/resident-details/refer-a-friend.tab";
import ExitTab from "../../components/tabs/resident-details/exit.tab";

// Responsive Tab
import LedgerRespTab from "../../components/tabs/resident-details/ledger-responsive.tab";
import ExitRespTab from "../../components/tabs/resident-details/exit-responsive.tab";

import { fetchResidentDetails, fetchResidentOnboardingDetails } from "../../helpers/services/api.services";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";
import { getFiscalYear } from "../../helpers/common/common.function";
import CollectionTab from "../../components/tabs/resident-details/collection-responsive.tab";
import PdcTab from "../../components/tabs/resident-details/pdc.tab";
import NewTicketModal from "../../components/customModals/NewTicketModal";

function ResidentDetails() {

    const [residentInfo, setResidentInfo] = useState({});
    const [residentOnboardingInfo, setResidentOnboardingInfo] = useState(null);
    const [residentBasicInfo, setResidentBasicInfo] = useState(null);
    const [isMobile, setIsMobile] = useState(false); // State to track if the device is mobile
    const { residentId } = useParams();
    const [activeTab, setActiveTab] = useState("schedule"); // Track the active tab
    const [scrollToCollection, setScrollToCollection] = useState(false); 
    const [showNewTicketModal, setShowNewTicketModal] = useState(false);

    useEffect(() => {
        // Function to check if the device is mobile
        const checkIsMobile = () => {
            setIsMobile(window.matchMedia("(max-width: 768px)").matches);
        };

        // Initial check
        checkIsMobile();

        // Add event listener for screen resizing
        window.addEventListener("resize", checkIsMobile);

        return () => {
            // Clean up the event listener
            window.removeEventListener("resize", checkIsMobile);
        };
    }, []);

    useEffect(() => {
        if (residentId != undefined) {
            loadResidentsDetails();
            loadResidentOnboardingDetails();
        }
    }, [residentId]);

    const loadResidentsDetails = async () => {
        let response = await fetchResidentDetails(residentId);

        if (response && response.status === "success") {
            setResidentInfo(response.data);
            setResidentBasicInfo(response?.data?.basic_details);
        }
    }

    const loadResidentOnboardingDetails = async () => {
        let response = await fetchResidentOnboardingDetails(residentId);

        if (response && response.status === "success") {
            setResidentOnboardingInfo(response.data);
        }
    }

    const handleGoToCollection = () => {
        setActiveTab("schedule"); 
        setScrollToCollection(true);
    };

    useEffect(() => {
        if (activeTab === "schedule" && scrollToCollection) {
            setTimeout(() => {
                const element = document.getElementById("collectionSchedule");
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
                setScrollToCollection(false);
            }, 100);
        }
    }, [activeTab, scrollToCollection]);

    return (
        <>
            {/* Web Content */}
            <div className="main-content-web">
                <div className="rdp-right-side-header">
                    <div className="d-flex justify-content-between align-items-center pb-2">
                        <div className="d-flex align-items-center">
                            <Link to={'/residents'}>
                                <img src="/images/Back.svg" alt="Back" />
                            </Link>
                            <div className="tit_point mx-3">{getFiscalYear()}</div>
                            <h2 className="m-0">{residentOnboardingInfo?.resident_name}</h2>
                        </div>
                        {residentOnboardingInfo && 
                            <div>
                            <button
                    className="basic-button"
                    onClick={() => setShowNewTicketModal(!showNewTicketModal)}
                  >
                    <img src="/images/plus-circle.svg" className="pr-2" alt="" />
                    New Ticket
                  </button>
                            </div>
                        
                        }
                    </div>
                    <div className="breadcum">
                        <ul>
                            <li><Link to={'/'}>Tenants</Link> <span>/</span></li>
                            <li><Link to={'/residents'}>Residents</Link> <span>/</span></li>
                            <li>{residentOnboardingInfo?.ys_id + ' - ' + residentOnboardingInfo?.resident_name}</li>
                        </ul>
                    </div>
                </div>

                {/* Details Tab View */}
                <div className="main_tab">
                    <Tabs
                        activeKey={activeTab}
                        id="resident-details-view"
                        className="mb-4"
                        onSelect={(k) => setActiveTab(k)}
                    >
                        <Tab eventKey="schedule" title="Schedule">
                            <ScheduleTab loadResidentsDetails={loadResidentOnboardingDetails} residentInfo={residentInfo} residentOnboardingInfo={residentOnboardingInfo} />
                        </Tab>
                        <Tab eventKey="ledger" title="Ledger">
                            <LedgerTab 
                                residentId={residentId}
                            />
                        </Tab>
                        <Tab eventKey="onboarding-info" title="Onboarding-info">
                            <OnBoardingInfoTab residentInfo={residentInfo} residentOnboardingInfo={residentOnboardingInfo}/>
                        </Tab>
                        <Tab eventKey="pdc" title="PDC">
                            <PdcTab resident_id={residentId} residentInfo={residentInfo} onboarding_info={residentOnboardingInfo}  />
                        </Tab>
                        <Tab eventKey="refer-a-friend" title="Refer a friend">
                            <ReferAFriendTab residentOnboardingInfo={residentOnboardingInfo} />
                        </Tab>
                        <Tab eventKey="exit" title="Exit">
                            <ExitTab
                                residentId={residentId}
                                residentInfo={residentInfo}
                                residentOnboardingInfo={residentOnboardingInfo}
                                loadResidentsDetails={() => loadResidentsDetails()}
                                loadResidentOnboardingDetails={() => loadResidentOnboardingDetails()}
                                handleGoToCollection={handleGoToCollection}
                            />
                        </Tab>
                    </Tabs>
                </div>
            </div>

            {/* Mobile Content - Only render when the device is mobile */}
            {isMobile && (
                <div className="main-content-mobile">
                    <div className="mob_details_header table_main_tit d-flex justify-content-between align-items-center pb-20">
                        <div>
                            <h2><Link to={'/residents'}><img src="/images/white_Back.svg" className="pr-2" alt="" /></Link>{residentOnboardingInfo?.resident_name ?? "--"}</h2>
                        </div>
                        <div className="details_right_top_part">{residentOnboardingInfo?.ys_id + ' - ' + residentOnboardingInfo?.resident_name}</div>
                    </div>

                    {/* Details Tab View */}
                    <div className="mob_details_middle bg_white">
                        <div className="main_tab">
                            <Tabs
                                defaultActiveKey="collection"
                                id="resident-details-view"
                                className="mb-4"
                            >
                                <Tab eventKey="collection" title="Collection">
                                    <CollectionTab
                                        residentId={residentId}
                                    />
                                </Tab>
                                <Tab eventKey="ledger" title="Ledger">
                                    <LedgerRespTab
                                        residentId={residentId}
                                    />
                                </Tab>
                                <Tab eventKey="exit" title="Exit">
                                    <ExitRespTab
                                        residentId={residentId}
                                        residentInfo={residentInfo}
                                        residentOnboardingInfo={residentOnboardingInfo}
                                        loadResidentsDetails={() => loadResidentsDetails()}
                                        loadResidentOnboardingDetails={() => loadResidentOnboardingDetails()}
                                    />
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            )}
                  {showNewTicketModal && (
        <NewTicketModal
          setShowModal={setShowNewTicketModal}
          showModal={showNewTicketModal}
          residentData={residentInfo}
        />
        )}
        </>
    );
}

export default withDefaultDashBoardLayout(ResidentDetails);