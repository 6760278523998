import { useEffect, useState, useContext } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import { Tab, Tabs } from 'react-bootstrap';

// TABS
import DetailsTab from "../../components/tabs/onboarding-details/details.tab";
import AgreementTab from "../../components/tabs/onboarding-details/agreement.tab";
import CollageContactsTab from "../../components/tabs/onboarding-details/college_contacts.tab";
import KycTab from "../../components/tabs/onboarding-details/kyc.tab";
import PocCheckinTab from "../../components/tabs/onboarding-details/check_in.tab";
import PropertyTab from "../../components/tabs/onboarding-details/property.tab";
import withDefaultDashBoardLayout from "../../components/layouts/withDefaultDashBoardLayout";

import DetailsRespTab from "../../components/tabs/onboarding-details/details-responsive.tab";
import AgreementRespTab from "../../components/tabs/onboarding-details/agreement-responsive.tab";
import PocCheckinRespTab from "../../components/tabs/onboarding-details/check_in-responsive.tab";

import { fetchOnboardingDetails, fetchResidentDetails } from "../../helpers/services/api.services";
import GlobalStateContext from "../../GlobalStateContext";
import NewTicketModal from "../../components/customModals/NewTicketModal";

function OnboardingDetails() {

    const {roles} = useContext(GlobalStateContext);
    const { state } = useLocation()
    const [residentInfo, setResidentInfo] = useState({});
    const [onboardingInfo, setOnboardingInfo] = useState({});
    const [residentBasicInfo, setResidentBasicInfo] = useState(null);
    const [key, setKey] = useState('details');
    const { onboardingId } = useParams();
    const [showNewTicketModal, setShowNewTicketModal] = useState(false);
    
    useEffect(() => {
        loadOnboardingDetails();
        // console.log("Location state -- ",state?.direct_checkin)
        if(state?.direct_checkin){
            setKey('check-in')
        }
    }, [onboardingId]);

    const loadOnboardingDetails = async () => {
        let response = await fetchOnboardingDetails(onboardingId);

        if (response && response?.status === "success") {
            let residentId = response?.data?.resident_id;

            // console.log(response.data);

            setTimeout(async () => {
                await setOnboardingInfo(response?.data);
                await loadResidentDetails(residentId);
                setResidentBasicInfo({
                    ys_id:response?.data?.id,
                    resident_name:response?.data?.resident_name
                })
            }, 1000);

        }
    }

    const loadResidentDetails = async (residentId) => {
        let response = await fetchResidentDetails(residentId);

        if (response && response?.status === "success") {
            setResidentInfo(response?.data);
            // setResidentBasicInfo(response?.data?.basic_details)
        }
    }

    return (
        <>
            <div className="main-content-web">
                <div className="rdp-right-side-header right-side-header">
                    <div className="d-flex justify-content-between align-items-center pb-2">
                        <div className="d-flex align-items-center">
                            <Link to={'/onboarding'}>
                                <img src="/images/Back.svg" alt="Back" />
                            </Link>
                            <div className="tit_point mx-3">{residentBasicInfo?.ys_id}</div>
                            <h2 className="m-0">{residentBasicInfo?.resident_name}</h2>
                            <span className="mx-3"><img src="/images/pipe.svg" alt="Pipe" /></span>&nbsp;
                            <span className="status_txt_top success_status">
                                <img src="/images/green-dot.svg" alt="Green Dot" />&nbsp;{onboardingInfo?.lead_status}
                            </span>
                        </div>
                        {residentBasicInfo && 
                            <div>
                            <button
                    className="basic-button"
                    onClick={() => setShowNewTicketModal(!showNewTicketModal)}
                  >
                    <img src="/images/plus-circle.svg" className="pr-2" alt="" />
                    New Ticket
                  </button>
                            </div>
                        }
                    </div>
                    <div className="breadcum">
                        <ul>
                            <li><Link to={'/'}>Tenants</Link> <span>/</span></li>
                            <li><Link to={'/onboarding'}>Onboarding</Link> <span>/</span></li>
                            <li>{residentBasicInfo?.ys_id + ' - ' + residentBasicInfo?.resident_name}</li>
                        </ul>
                    </div>
                </div>

                {/* Details Tab View */}
                <div className="main_tab">
                    <Tabs
                        defaultActiveKey="details"
                        id="onboarding-details-view"
                        className="mb-4"
                    >
                        <Tab eventKey="details" title="Details">
                            <DetailsTab 
                                residentInfo={residentInfo} 
                                onboardingInfo={onboardingInfo} 
                            />
                        </Tab>
                        <Tab eventKey="property" title="Property">
                            <PropertyTab 
                                residentInfo={residentInfo} 
                                onboardingInfo={onboardingInfo}
                            />
                        </Tab>
                        <Tab eventKey="collegeContacts" title={`${onboardingInfo?.resident_type == "working_professional" ? 'Company' :'College'} & Contacts`}>
                            <CollageContactsTab 
                                residentInfo={residentInfo} 
                                onboardingInfo={onboardingInfo} 
                            />
                        </Tab>
                        <Tab eventKey="kyc" title="KYC">
                            <KycTab 
                                residentInfo={residentInfo} 
                                onboardingInfo={onboardingInfo} 
                            />
                        </Tab>
                        <Tab eventKey="agreement" title="Agreement">
                            <AgreementTab 
                                residentInfo={residentInfo} 
                                onboardingInfo={onboardingInfo} 
                            />
                        </Tab>
                        {
                            !roles?.finance_manager ? 
                            <Tab eventKey="checkIn" title="Check-in">
                                <PocCheckinTab 
                                    onboardingInfo={onboardingInfo} 
                                    residentInfo={residentInfo?.basic_details ?? {}} 
                                    onAction={loadOnboardingDetails} />
                            </Tab>
                            : null
                        }
                    </Tabs>
                </div>
            </div>

            {/* RESPONSIVE SECTION */}
            <div className="main-content-mobile">
                <div className="mob_details_header table_main_tit d-flex justify-content-between align-items-center pb-20">
                    <div>
                        <h2>
                            <Link to={'/onboarding'}>
                                <img src="/images/white_Back.svg" className="pr-2" alt="" />
                            </Link>{' ' + residentBasicInfo?.resident_name}
                        </h2>
                    </div>
                    <div className="details_right_top_part">{residentBasicInfo?.ys_id}</div>
                </div>

                {/* Details Tab View */}
                <div className="mob_details_middle bg_white">
                    <div className="main_tab">
                        <Tabs
                            defaultActiveKey="details"
                            id="resident-details-view"
                            className="mb-4"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="details" title="Details">
                                <DetailsRespTab residentInfo={residentInfo} onboardingInfo={onboardingInfo} />
                            </Tab>
                            <Tab eventKey="agreement" title="Agreement">
                                <AgreementRespTab 
                                    residentInfo={residentInfo} 
                                    onboardingInfo={onboardingInfo} 
                                />
                            </Tab>
                            {
                            !roles?.finance_manager ? 
                            <Tab eventKey="check-in" title="Check-in">
                                <PocCheckinRespTab 
                                    onboardingInfo={onboardingInfo} 
                                    onAction={loadOnboardingDetails} 
                                />
                            </Tab>
                                : null
                            }
                        </Tabs>
                    </div>
                </div>
            </div>
            
      {showNewTicketModal && (
        <NewTicketModal
          setShowModal={setShowNewTicketModal}
          showModal={showNewTicketModal}
          residentData={residentBasicInfo}
        />
        )}
        </>
    );
}

export default withDefaultDashBoardLayout(OnboardingDetails);