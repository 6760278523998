import React, { useEffect, useState } from "react";
import withDefaultDashBoardLayout from "../../../components/layouts/withDefaultDashBoardLayout";
import { Link, useNavigate } from "react-router-dom";
import CreateBlog from "./modals/CreateBlog";
import { fetchAllBlogs } from "../../../helpers/services/admin.services";
import DataTable from "react-data-table-component";
import moment from "moment/moment";

const BlogPage = () => {
  const [allBlogs, setAllBlogs] = useState([]);
  const [openCreateModal, setopenCreateModal] = useState(false);
  const [page, setPage] = useState(1);
  const [serachObject, setSerachObject] = useState({});
  const [sortObject, setSortObject] = useState({});
  const [filterAcitveCount, setFilterAcitveCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [blogRows, setblogRows] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [searchQuery, setSearcQuery] = useState("");

  const loadBlogData = async (
    currentPage,
    searchPayload,
    sortPayload,
    current_rowPerPage
  ) => {
    setLoading(true);
    let response = await fetchAllBlogs(
      currentPage,
      searchPayload,
      sortPayload,
      current_rowPerPage
    );

    if (
      response &&
      response?.status === "success" &&
      response.data &&
      response.data.length > 0
    ) {
      setblogRows(response.data);

      setTotalRows(response?.total_count);

      setPage(currentPage);
      setLoading(false);
    } else {
      setblogRows([]);
      // handleCloseFilterModal();
      setLoading(false);
    }
  };

  const navigate = useNavigate();

  const handleDetailsPageRedirection = (data) => {
    navigate(`/website/blog/${data?.id}`);
  };

  const handlePageChange = (page) => {
    loadBlogData(page, serachObject, sortObject, rowsPerPage);
  };

  useEffect(() => {
    loadBlogData(page, serachObject, sortObject, rowsPerPage);
  }, []);

  const columns = [
    {
      name: "id",
      selector: (row) => row.id,
      width: "5%",
    },
    {
      name: "Title",
      selector: (row) => row.title,
      width: "30%",
    },
    {
      name: "Created By",
      selector: (row) => row?.author_details?.author_name,
      width: "15%",
    },
    {
      name: "Created On",
      selector: (row) => moment(row.created_at).format("DD-MM-YYYY"),
      width: "15%",
    },
    {
      name: "Updated On",
      selector: (row) => moment(row.updated_at).format("DD-MM-YYYY"),
      width: "15%",
    },
    {
      name: "Created By",
      selector: (row) => row?.author_details?.author_name,
      width: "15%",
    },
    {
      name: "",
      selector: (row) => row.id,
      width: "5%",
      cell: (row) => (
        <>
          <Link to={`/website/blog/${row?.id}`}>
            <img src="/images/right_arrow.svg" alt="Details Icon" />
          </Link>
        </>
      ),
    },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    loadBlogData(1, { blog_title: searchQuery }, {}, rowsPerPage);
  };

  return (
    <>
      <div className="property-header">
        <div className="right-side-header">
          <div className="d-flex justify-content-between align-items-center pb-2">
            <div className="d-flex align-items-center">
              <Link to={"/website/blog"}>
                <img src="/images/Back.svg" alt="Back" />
              </Link>
              <h2>Website</h2>
            </div>
          </div>
          <div className="breadcum">
            <ul>
              <li>
                <a href="#">Website</a> <span>/</span>
              </li>
              <li>Blog</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-center pb-2 mb-2">
        <form onSubmit={handleSearch} className="w-75">
          <div
            className="form_control_box w-100 mt-2"
            style={{ position: "relative" }}
          >
            <input
              type="text"
              className="form-control"
              placeholder="Search by title"
              value={searchQuery}
              onChange={(e) => setSearcQuery(e.target.value)}
            />
            {searchQuery.length > 0 && (
              <div
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "30px",
                  color: "#FF5700",
                  cursor: "pointer",
                }}
                onClick={handleSearch}
              >
                Search
              </div>
            )}
          </div>
        </form>
        <div className="">
          <button
            className="basic-button"
            onClick={() => setopenCreateModal(!openCreateModal)}
          >
            <img src="/images/plus-circle.svg" className="pr-2" alt="" />
            Add Blog
          </button>
        </div>
      </div>
      <div className="sr_data_table">
        <DataTable
          columns={columns}
          data={blogRows}
          progressPending={loading}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          onChangePage={handlePageChange}
          fixedHeader={true}
          onRowClicked={handleDetailsPageRedirection}
          fixedHeaderScrollHeight={"430px"}
        ></DataTable>
      </div>
      {openCreateModal && (
        <CreateBlog
          openModal={openCreateModal}
          setOpenModal={setopenCreateModal}
        />
      )}
    </>
  );
};

export default withDefaultDashBoardLayout(BlogPage);
