import { useEffect, useState, useContext } from "react";
import { Modal, Form as BootstrapForm } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import Fancybox from "../../fancy-box/FancyBox";
import "react-datepicker/dist/react-datepicker.css";
import "../../../css/resident-details-tab.css";
import {
  fetchResidentExitDetails,
  updateExitStatus,
  confirmNoc,
  checkForOutStanding,
} from "../../../helpers/services/api.services";
import { Formik, Form, Field, FieldArray, useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import toast from "react-hot-toast";
import GlobalStateContext from "../../../GlobalStateContext";
import { Link } from "react-router-dom";

function ExitTab({
  residentId,
  residentInfo,
  residentOnboardingInfo,
  loadResidentsDetails,
  loadResidentOnboardingDetails,
  handleGoToCollection
}) {
  const { roles } = useContext(GlobalStateContext);
  const [showAbscondingModal, setShowAbscondingModal] = useState(false);
  const [showConfirmNocModal, setShowConfirmNocModal] = useState(false);
  // const [startDate, setStartDate] = useState();
  const [exitData, setExitData] = useState({});

  // const [status,setStatus] = useState("active")
  const [internalOperation, setInternalOperation] = useState("");
  const [showTerminteModel, setShowTerminateModel] = useState(false);
  const [initialValues_confirm_noc, setInitialValues_confirm_noc] = useState(
    []
  );
  const [showViewNocModel, setShowViewNocModal] = useState(false);
  const [openAbscondModal, setOpenAbscondMOdal] = useState(false);
  const [openExitModal, setOpenExitMOdal] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState("pending");
  const [pendingPaymentData, setPendingPaymentData] = useState([]);
  const [pendingPaymentMeta, setPendingPaymentMeta] = useState({});
  const [pendingPaymentCheckLoader , setPendingPaymentCheckLoader] = useState(false)
  // const [isExitInitiated, setIsExitInitiated] = useState(false)

  useEffect(() => {
    loadExitDetails();
  }, [residentId]);

  // useEffect(()=>{
  //     let contract_end_date = residentInfo?.contract_details?.contract_end_date ? new Date(residentInfo?.contract_details?.contract_end_date) : ""
  //     if(contract_end_date){
  //         const todays_date = new Date();
  //         const diffTime = Math.abs(contract_end_date - todays_date);
  //         const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  //         if(diffDays<=60){
  //             setIsExitInitiated(true)
  //         }
  //     }
  // },[residentInfo])

  // Abscoding Modal
  const handleCloseAbscondingModal = () => {
    setShowAbscondingModal(false);
  };
  const handleShowAbscondingModal = () => {
    setShowAbscondingModal(true);
  };

  const handleCloseTerminateModal = () => {
    setInternalOperation("");
    setShowTerminateModel(false);
  };
  const handleShowTerminateModal = (operation) => {
    setInternalOperation(operation);
    setShowTerminateModel(true);
  };

  // Confirm NOC Modal
  const handleCloseConfirmNocModal = () => setShowConfirmNocModal(false);
  const handleShowConfirmNocModal = () => {
    setInitialValues_confirm_noc(exitData.resident_room_assets);
    setShowConfirmNocModal(true);
  };

  const loadExitDetails = async () => {
    let response = await fetchResidentExitDetails(residentId);
    if (response.status === "success") {
      // console.log("response -- ",response);
      setExitData(response.data);
    }
  };

  const initialValues = {
    // date:"",
    status: "absconded",
    absconded_reason: "",
  };

  const validationSchema = Yup.object().shape({
    date: Yup.date(),
    status: Yup.string(),
    absconded_reason: Yup.string(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        delete values.date;
        values.absconded_reason = values.absconded_reason.replace(
          /(\r\n|\n|\r)/gm,
          " "
        );
        const response = await updateExitStatus(residentId, values);
        await toast.success("Exit status has been updated successfully.");
        loadResidentsDetails();
        checkForOutStandingBills();
        loadExitDetails();
        loadResidentOnboardingDetails();
        handleCloseAbscondingModal();
      } catch (error) {
        toast.error(
          error?.response?.data?.error ||
            "Something went wrong please try again"
        );
      }
    },
  });

  const checkForOutStandingBills = async () => {
    setPendingPaymentCheckLoader(true)
    try {
      const response = await checkForOutStanding(residentId);
      if (response.status === "success") {
        if (response?.data?.length > 0) {
          setPaymentStatus("pending");
          setPendingPaymentData(response?.data);
          return "pending"
        } else if (response?.data?.length == 0) {
          setPaymentStatus("complete");
          setPendingPaymentData(response?.data);
          setPendingPaymentMeta(response?.meta);
          return "complete"
        }
      } else {
        setPendingPaymentData(null);
        setPendingPaymentMeta(null);
      }
    } catch (error) {
      console.log(error);
    }finally{
      setPendingPaymentCheckLoader(false)
    }
  };

  const initialValues_terminate = {
    // status:"",
    portend_amount: "",
  };

  const validationSchema_terminate = Yup.object().shape({
    status: Yup.string(),
    portend_amount: Yup.number()
      .positive("Partend amount must be positive number")
      .integer("Partend amount must be an integer"),
  });

  const formik_terminate = useFormik({
    initialValues: initialValues_terminate,
    validationSchema: validationSchema_terminate,
    onSubmit: async (values) => {
      try {
        const response = await updateExitStatus(residentId, {
          ...values,
          status: internalOperation,
        });
        await toast.success("Exit status has been updated successfully.");
        formik_terminate.resetForm();
        loadResidentsDetails();
        loadResidentOnboardingDetails();
        loadExitDetails();
        handleCloseTerminateModal();
      } catch (error) {
        toast.error(
          error?.response?.data?.error ||
            "Something went wrong please try again"
        );
      }
    },
  });

  const handleMarkAsLeftClick = async() => {

    try {

     const response = await checkForOutStandingBills();

     if (response == "pending") {
       handleShowAbscondingModal("abscond");
     }
     if (response == "complete") {
       setOpenExitMOdal(true);
     }
      
    } catch (error) {
      
    }

  };

  const handleConfirmExitSubmitHandle = async () => {
    try {
      const data = {
        status: "exit_initiated",
      };

      const response = await updateExitStatus(residentId, data);

      if (response.status == "success") {
        setOpenExitMOdal(false);
        toast.success("Exit initiated");
        loadExitDetails();
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.error || "Something went wrong please try again"
      );
    }
  };

  return (
    <>
      <div className="exit_tab_box">
        {residentInfo?.contract_details?.status === "absconded" ? (
          <div className="alert_error_box mb-3">
            The resident has absconded on{" "}
            {residentInfo?.contract_details?.absconded_on
              ? moment(residentInfo.contract_details.absconded_on).format(
                  "DD-MM-YYYY"
                )
              : "--"}
          </div>
        ) : null}

        {/* {
                    isExitInitiated ? */}
        {exitData?.is_exit_journey_started ? (
          <div className="alert_warring_box mb-3">
            Exit confirmation trigger initiated to Resident. Pending
            confirmation
          </div>
        ) : null}

        {residentInfo?.contract_details?.exit_confirmed ? (
          <div className="alert_success_box mb-3">
            Student Confirmed on Exit
          </div>
        ) : null}
        {exitData?.contract_details?.exit_initiated &&
        !exitData?.contract_details?.exit_confirmed ? (
          <div className="alert_warring_box mb-3">Exit Initiated</div>
        ) : null}

        {residentInfo?.contract_details?.status === "terminated" ? (
          <div className="alert_error_box mb-3">The resident is terminated</div>
        ) : null}

        {exitData?.resident_transfer_details &&
        exitData?.is_extend_journey_started ? (
          <div className="alert_success_box mb-3">
            Student Confirmed on Transfer
          </div>
        ) : null}

        <div className="table_main_tit pb-3">
          <h2>Summary</h2>
        </div>
        <div className="mb-4">
          <div className="d-flex justify-content-between align-items-center exit_top">
            <div className="d-flex small_main_box">
              <div className="small_box_data">
                <h2>Move-Date as per contract</h2>
                {/* <span>2345</span> */}
                <span>
                  {residentInfo?.contract_details?.contract_start_date
                    ? moment(
                        residentInfo.contract_details.contract_start_date
                      ).format("DD-MM-YYYY")
                    : "--"}
                </span>
              </div>
              <div className="small_box_data">
                <h2>Proposed Refunds</h2>
                {/* <span>60,000</span> */}
                <span>
                  {exitData?.proposed_refund
                    ? String(exitData?.proposed_refund)
                    : 0}
                </span>
              </div>
            </div>
            {pendingPaymentData && (
              <>
                {exitData?.contract_details?.status == "absconded" ||
                exitData?.contract_details?.exit_initiated == true ||
                exitData?.contract_details?.status ==
                  "terminated" ? 
                  <button
                  className="orange_brd_button p-16-20-button"
                >
                  {exitData?.contract_details?.status == "active" ? "Exit Initiated" : `Marked as ${exitData?.contract_details?.status}`}
                </button>
                  : roles?.super_admin ||
                  roles?.admin ||
                  roles?.property_manager ||
                  roles?.warden ||
                  roles?.finance_manager ||
                  roles?.finance_head ||
                  roles?.cfo ||
                  roles?.customer_care ? (
                  <button
                    className="orange_brd_button p-16-20-button"
                    onClick={handleMarkAsLeftClick}
                    disabled={pendingPaymentCheckLoader}
                  >
                    {pendingPaymentCheckLoader ? "Loading.." : "Mark as Left"}
                  </button>
                ) : null}
              </>
            )}
          </div>
        </div>

        {residentInfo?.contract_details?.status === "absconded" ? (
          <div className="brd_box_card">
            <div className="box_head">
              <div className="d-flex align-items-center">
                <h2>
                  {" "}
                  <img src="/images/file-07.svg" className="pr-2" alt="" />{" "}
                  Abscond Details
                </h2>
              </div>
            </div>
            <div className="box_contain">
              <div className="sub_exit_box">
                <div className="d-flex align-items-center">
                  <div className="sub_exit_data_txt">
                    <h3>Abscond date</h3>
                    <span>
                      {exitData?.contract_details?.absconded_on
                        ? moment(exitData.contract_details.absconded_on).format(
                            "DD-MM-YYYY"
                          )
                        : "--"}
                    </span>
                  </div>
                  <div className="sub_exit_data_txt">
                    <h3>Comment by PM</h3>
                    <span>
                      {residentInfo?.contract_details?.absconded_reason
                        ? residentInfo.contract_details.absconded_reason
                        : "--"}
                    </span>
                  </div>
                  <div className="sub_exit_data_txt">
                    <h3>Contract Value Due</h3>
                    <span>
                      {residentInfo?.contract_details?.contract_value
                        ? residentInfo.contract_details.contract_value
                        : "--"}
                    </span>
                  </div>
                  <div className="sub_exit_data_txt">
                    <h3>SDM Amount</h3>
                    <span>Rooms, Services & Cleanliness</span>
                  </div>
                </div>
              </div>
            </div>
            {roles?.super_admin ||
            roles?.finance_manager ||
            roles?.finance_head ||
            roles?.cfo ? (
              <div className="box_footer brd-top">
                <button
                  className="orange_brd_button p-12-16-button"
                  onClick={() => handleShowTerminateModal("active")}
                >
                  REGULARISE
                </button>
                <button
                  className="basic-button ml-2"
                  onClick={() => handleShowTerminateModal("terminated")}
                >
                  TERMINATE
                </button>
              </div>
            ) : null}
          </div>
        ) : null}

        <div className="brd_box_card">
          <div className="box_head">
            <div className="d-flex align-items-center">
              <h2>
                {" "}
                <img
                  src="/images/corner-down-right.svg"
                  className="pr-2"
                  alt=""
                />{" "}
                Resident Exit Details
              </h2>
              {exitData?.is_exit_journey_started ? (
                <div className="tit_point green_point mx-3">Initiated</div>
              ) : (
                <div className="tit_point mx-3">
                  {residentInfo?.basic_details?.ys_id ?? ""}
                </div>
              )}
            </div>
          </div>
          <div className="box_contain">
            <div className="sub_exit_box">
              <div className="d-flex align-items-center">
                <div className="sub_exit_data_txt">
                  <h3>Exit confirmation date</h3>
                  <span>
                    {exitData?.exit_confimation_date
                      ? moment(exitData.exit_confimation_date).format(
                          "DD-MM-YYYY"
                        )
                      : "--"}
                  </span>
                </div>
                <div className="sub_exit_data_txt">
                  <h3>Asset Checklist</h3>
                  {exitData?.asset_checklist_completed ? (
                    <div className="tit_point green_point mx-3">Completed</div>
                  ) : (
                    <span className="gray_point d-inline">Not Completed</span>
                  )}
                </div>
                <div className="sub_exit_data_txt">
                  <h3>Exit Rating</h3>
                  <span>
                    {exitData?.exit_review ? exitData.exit_review : "--"}
                  </span>
                </div>
                <div className="sub_exit_data_txt">
                  <h3>Exit Reason</h3>
                  <span>
                    {exitData?.exit_reason ? exitData.exit_reason : "--"}
                  </span>
                </div>
              </div>
            </div>
            <div className="sub_exit_box">
              <h2>Refund bank account</h2>
              <div className="d-flex align-items-center ">
                <div className="sub_exit_data_txt">
                  <h3>Name as per Bank</h3>
                  {exitData?.bank_details?.account_holder_name
                    ? exitData.bank_details.account_holder_name
                    : "--"}
                </div>
                <div className="sub_exit_data_txt">
                  <h3>Account Number</h3>
                  {exitData?.bank_details?.account_number
                    ? exitData.bank_details.account_number
                    : "--"}
                </div>
                <div className="sub_exit_data_txt">
                  <h3>IFSC Code</h3>
                  {exitData?.bank_details?.ifsc_code
                    ? exitData.bank_details.ifsc_code
                    : "--"}
                </div>
                <div className="sub_exit_data_txt">
                  <h3>Bank Name - Branch</h3>
                  {exitData?.bank_details?.branch_address
                    ? exitData.bank_details.branch_address
                    : "--"}
                </div>
              </div>
            </div>

            {residentInfo?.contract_details?.noc_confirmed ? (
              <div className="sub_exit_box">
                <h2>Refund Details</h2>
                <div className="d-flex align-items-center ">
                  <div className="sub_exit_data_txt">
                    <h3>Refund Initiated</h3>
                    {residentInfo?.contract_details?.refund_initiated == true
                      ? "True"
                      : "False"}
                  </div>
                  <div className="sub_exit_data_txt">
                    <h3>Is Refunded</h3>
                    {residentInfo?.contract_details?.is_refunded == true
                      ? "True"
                      : "False"}
                  </div>
                  <div className="sub_exit_data_txt">
                    <h3>Refunded Amount</h3>
                    {residentInfo?.contract_details?.updated_refund_amount
                      ? residentInfo?.contract_details?.updated_refund_amount
                      : "--"}
                  </div>
                  <div className="sub_exit_data_txt">
                    <h3>Refund Date</h3>
                    {exitData?.contract_details?.refund_date
                      ? moment(exitData?.contract_details?.refund_date).format(
                          "DD-MM-YYYY"
                        )
                      : "--"}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className="box_footer brd-top">
            {residentInfo?.contract_details?.noc_confirmed ? (
              <button
                className="orange_brd_button p-12-16-button ml-2"
                onClick={() => setShowViewNocModal(true)}
              >
                view NOC by pm
              </button>
            ) : (roles?.super_admin ||
                roles?.admin ||
                roles?.property_manager ||
                roles?.warden ||
                roles?.finance_manager ||
                roles?.finance_head ||
                roles?.cfo) &&
              exitData?.asset_checklist_completed ? (
              <button
                className="basic-button"
                onClick={() => {
                  handleShowConfirmNocModal();
                }}
              >
                Confirm NOC
              </button>
            ) : null}
          </div>
        </div>
        {exitData?.resident_transfer_details &&
        exitData?.is_extend_journey_started ? (
          <div className="brd_box_card">
            <div className="box_head">
              <div className="d-flex align-items-center">
                <h2>
                  {" "}
                  <img
                    src="/images/switch-horizontal.svg"
                    className="pr-2"
                    alt=""
                  />{" "}
                  Resident Transfer Details
                </h2>
              </div>
            </div>
            <div className="box_contain">
              <div className="sub_exit_box">
                <div className="d-flex align-items-center">
                  <div className="sub_exit_data_txt">
                    <h3>Lead creation date</h3>
                    <span>
                      {exitData?.resident_transfer_details
                        ?.new_lead_creation_date
                        ? moment(
                            exitData.resident_transfer_details
                              .new_lead_creation_date
                          ).format("DD-MM-YYY")
                        : "--"}
                    </span>
                  </div>
                  <div className="sub_exit_data_txt">
                    <h3>Lead ID</h3>
                    <span>
                      {exitData?.resident_transfer_details?.new_lead_id
                        ? exitData.resident_transfer_details.new_lead_id
                        : "--"}
                    </span>
                  </div>
                  <div className="sub_exit_data_txt">
                    <h3>Preferred room</h3>
                    <div className="d-flex align-items-center">
                      {exitData?.resident_transfer_details?.room_change ? (
                        <div className="tit_point green_point mx-3">
                          Different
                        </div>
                      ) : (
                        <div className="tit_point green_point mx-3">
                          Current
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sub_exit_data_txt">
                    <h3>Preferred property</h3>
                    <div className="d-flex align-items-center">
                      {exitData?.resident_transfer_details?.is_same_property ? (
                        <div className="tit_point green_point mx-3">
                          Current
                        </div>
                      ) : (
                        <div className="tit_point green_point mx-3">
                          Different
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* ABSCONDING MODAL */}
      <Modal
        show={showAbscondingModal}
        onHide={handleCloseAbscondingModal}
        centered
        size="md"
        className="abscoding_modal"
      >
        <Modal.Body>
          <div className="head_part_date_modal">
            <span onClick={handleCloseAbscondingModal} className="close_modal">
              <img src="/images/cross_modal.svg" alt="Close Icon" />
            </span>
            <h2>Confirm Abscond Status</h2>
            <p>
              <b>
                Are you sure you want to mark this resident as Abscond? An email
                notification will also be sent to the resident informing them of
                this status.
              </b>
            </p>
          </div>
          <form onSubmit={formik.handleSubmit} id="abscond_form">
            <div className="modal_input_main">
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Enter Date</label>
                <ReactDatePicker
                  id="date_"
                  name="date_"
                  selected={
                    formik.values.date ? formik.values.date : new Date()
                  }
                  onChange={(date) => formik.setFieldValue("date", date)}
                  maxDate={new Date()}
                />
              </div>

              <div className="form-group">
                <textarea
                  className="form-control"
                  placeholder="Add comments (Optional)"
                  id="absconded_reason"
                  name="absconded_reason"
                  value={formik.values.absconded_reason}
                  onChange={(e) =>
                    formik.setFieldValue("absconded_reason", e.target.value)
                  }
                ></textarea>
                <div
                  className="d-flex mt-2"
                  style={{ fontSize: "12px", color: "red" }}
                >
                  Oops! Payment is due -
                  <p
                    onClick={()=> {
                      handleCloseAbscondingModal()
                      handleGoToCollection()
                    }}
                    style={{ textDecoration: "underline", color: "red" , cursor:"pointer" }}
                  >
                    Check Now.
                  </p>
                </div>
              </div>

              <div className="modal_btn_footer">
                <button
                  type="button"
                  onClick={handleCloseAbscondingModal}
                  className="orange_brd_button p-12-16-button mr-3"
                >
                  CANCEL
                </button>
                {/* <button className="basic-button bg_gray_button">CONFIRM</button> */}
                <button
                  type="submit"
                  className="basic-button"
                  form="abscond_form"
                >
                  MARK AS ABSCOND
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* TERMINATE/REGULARISE MODAL */}
      <Modal
        show={showTerminteModel}
        onHide={handleCloseTerminateModal}
        centered
        size="md"
        className="abscoding_modal"
      >
        <Modal.Body>
          <div className="head_part_date_modal">
            <span onClick={handleCloseTerminateModal} className="close_modal">
              <img src="/images/cross_modal.svg" alt="Close Icon" />
            </span>
            <h2>
              {internalOperation === "active" ? "Regularise" : "Terminate"}{" "}
              Resident
            </h2>
            <p>To terminate the resident, enter the amount to be walved off.</p>
          </div>
          <form onSubmit={formik_terminate.handleSubmit} id="terminte_form">
            <div className="modal_input_main">
              <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">
                  Partend Amount
                </label>
                <input
                  id="portend_amount"
                  name="portend_amount"
                  type="number"
                  placeholder="Enter Amount"
                  value={formik_terminate.values.portend_amount}
                  style={
                    formik_terminate.touched.portend_amount &&
                    formik_terminate.errors.portend_amount
                      ? {
                          borderColor: "red",
                        }
                      : null
                  }
                  onChange={(e) =>
                    formik_terminate.setFieldValue(
                      "portend_amount",
                      e.target.value
                    )
                  }
                  onBlur={formik_terminate.handleBlur}
                />
                {formik_terminate.touched.portend_amount &&
                formik_terminate.errors.portend_amount ? (
                  <div className="error-label">
                    {formik_terminate.errors.portend_amount}
                  </div>
                ) : null}
              </div>

              <div className="modal_btn_footer">
                <button
                  type="button"
                  onClick={handleCloseTerminateModal}
                  className="orange_brd_button p-12-16-button mr-3"
                >
                  CANCEL
                </button>
                <button
                  type="submit"
                  className="basic-button"
                  form="terminte_form"
                >
                  CONFIRM
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* CONFIRM NOC MODAL */}
      <Modal
        show={showConfirmNocModal}
        onHide={handleCloseConfirmNocModal}
        centered
        size="lg"
        className="confirm_noc_modal"
      >
        <Modal.Body>
          <Formik
            initialValues={{ resident_room_assets: initialValues_confirm_noc }}
            onSubmit={async (values) => {
              const finalPayload = values.resident_room_assets.map((asset) => ({
                id: asset.id,
                damage_percent: asset.damage_percent,
              }));
              //   console.log("finalPayload -- ",finalPayload)
              try {
                const response = await confirmNoc(residentId, {
                  resident_room_assets: finalPayload,
                });
                if(response?.status?.toLowerCase() == "success"){
                  toast.success("NOC has been confirmed successfully.");
                  handleCloseConfirmNocModal();
                }else{
                  toast.error(`Something went wrong while updating NOC confirmation`)
                }
              } catch (error) {
                await toast.error(
                  "Something went wrong while updating NOC confirmation."
                );
              }
            }}
            render={(props) => (
              <Form>
                <div className="head_part_date_modal">
                  <span
                    onClick={handleCloseConfirmNocModal}
                    className="close_modal"
                  >
                    <img src="/images/cross_modal.svg" alt="Close Icon" />
                  </span>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <div>
                      <h2>Asset Verification</h2>
                      <p className="m-0">
                        Kindly check the assets and enter the damage percentage
                      </p>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="orange_brd_button p-12-16-button mr-3"
                        onClick={handleCloseConfirmNocModal}
                      >
                        CANCEL
                      </button>
                      <button type="submit" className="basic-button">
                        save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="modal_data_part">
                  <div>
                    <Fancybox
                      options={{
                        Carousel: {
                          infinite: false,
                        },
                        thumbs: {
                          autoStart: false,
                        },
                      }}
                    >
                      <FieldArray
                        name="friends"
                        render={(arrayHelpers) => (
                          <>
                            {props.values.resident_room_assets &&
                            props.values.resident_room_assets.length > 0
                              ? props.values.resident_room_assets.map(
                                  (asset, index) => (
                                    <div className="col-md-6 col-lg-4">
                                      <div className="modal_list_main">
                                        <div className="modal_list_img pos_rel">
                                          <a
                                            data-fancybox="gallery"
                                            // href="/images/list_1.png"
                                            href={asset.uploaded_asset_url}
                                          >
                                            <img
                                              alt=""
                                              src={asset.uploaded_asset_url}
                                            />
                                            <div className="black_shedow">
                                              <img
                                                src="/images/black_shedow.png"
                                                alt="Close Icon"
                                              />{" "}
                                            </div>
                                            <div className="list_data_modal">
                                              <h2>{asset.asset_slug}</h2>
                                              <p>
                                                Value of asset :{" "}
                                                <span>
                                                  ₹{" "}
                                                  {asset?.asset_amount
                                                    ? asset.asset_amount
                                                    : "0"}
                                                </span>
                                              </p>
                                            </div>
                                          </a>
                                        </div>
                                      </div>
                                      <div className="modal_input_main">
                                        <div className="form-group">
                                          <label htmlFor="exampleFormControlSelect1">
                                            Damage by student
                                          </label>
                                          <select
                                            id={`asset.${asset.id}`}
                                            name={`asset.${asset.id}`}
                                            className="form-control"
                                            value={
                                              props.values.resident_room_assets[
                                                index
                                              ].damage_percent
                                            }
                                            onChange={(e) =>
                                              props.setFieldValue(
                                                `resident_room_assets[${index}].damage_percent`,
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="">
                                              Select damage percentage
                                            </option>
                                            <option value="0%">0%</option>
                                            <option value="20%">20%</option>
                                            <option value="40%">40%</option>
                                            <option value="60%">60%</option>
                                            <option value="80%">80%</option>
                                            <option value="100%">100%</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )
                              : null}
                          </>
                        )}
                      />

                      {/* ===================== */}
                    </Fancybox>
                  </div>
                </div>
              </Form>
            )}
          />
        </Modal.Body>
      </Modal>

      <Modal
        show={showViewNocModel}
        onHide={() => setShowViewNocModal(false)}
        centered
        size="lg"
        className="confirm_noc_modal"
      >
        <Modal.Body>
          <div className="head_part_date_modal">
            <span
              onClick={() => setShowViewNocModal(false)}
              className="close_modal"
            >
              <img src="/images/cross_modal.svg" alt="Close Icon" />
            </span>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2>Asset Verification</h2>
                <p className="m-0">
                  Kindly check the assets and enter the damage percentage
                </p>
              </div>
            </div>
          </div>
          <div className="modal_data_part">
            <div>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                  thumbs: {
                    autoStart: false,
                  },
                }}
              >
                {exitData?.resident_room_assets?.map((asset) => (
                  <div className="col-md-6 col-lg-4">
                    <div className="modal_list_main">
                      <div className="modal_list_img pos_rel">
                        <a
                          data-fancybox="gallery"
                          // href="/images/list_1.png"
                          href={asset.uploaded_asset_url}
                        >
                          <img alt="" src={asset.uploaded_asset_url} />
                          <div className="black_shedow">
                            <img
                              src="/images/black_shedow.png"
                              alt="Close Icon"
                            />{" "}
                          </div>
                          <div className="list_data_modal">
                            <h2>{asset.asset_slug}</h2>
                            {/* <p>Value of asset :  <span>10,000</span></p> */}
                            <p>
                              Value of asset :{" "}
                              <span>
                                {asset?.asset_amount ? asset.asset_amount : "0"}
                              </span>
                            </p>
                          </div>
                          <div className="list_label_box">
                            <h3>Damange</h3>
                            <span className="orange_clr">
                              {asset?.damage_percent
                                ? asset?.damage_percent
                                : 0}{" "}
                              %
                            </span>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                ))}

                {/* ===================== */}
              </Fancybox>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={openAbscondModal}
        onHide={() => setOpenAbscondMOdal(false)}
        centered
        size="md"
        className="abscoding_modal"
      ></Modal>

      <Modal
        show={openExitModal}
        onHide={() => setOpenExitMOdal(false)}
        centered
        size="md"
        className="abscoding_modal"
      >
        <Modal.Body>
          <div className="head_part_date_modal">
            <span
              onClick={() => setOpenExitMOdal(false)}
              className="close_modal"
            >
              <img src="/images/cross_modal.svg" alt="Close Icon" />
            </span>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2>Confirm Left Status</h2>
                <p className=" mt-2 text-success">No payment is due</p>

                <p className="mb-4">
                  {pendingPaymentMeta?.is_extend_contract_ledger
                    ? 'The sales team created a new contract, and a ledger has been created. The "Mark as Left" option will mark the resident as LEFT in the existing contract. No further exit process will be required.'
                    : pendingPaymentMeta?.is_extend_contract_draft
                    ? 'The sales team created a new contract. The "Mark as Left" option will mark the resident as LEFT in the existing contract. No further exit process will be required.'
                    : pendingPaymentMeta?.is_extend_requested
                    ? 'The resident initiated the extension. The "Mark as Left" option will mark the resident as LEFT in the existing contract. No further exit process will be required.'
                    : "Are you sure you want to mark this resident as LEFT? You may also check with the resident regarding any potential extensions or renewals if they wish to continue."}
                </p>

                <p>
                  <b>
                    {pendingPaymentMeta?.is_extend_contract_ledger
                      ? "Note: Connect with IT if any changes or cancellations are required for the future contract."
                      : pendingPaymentMeta?.is_extend_contract_draft
                      ? "Note: Connect with the sales team if any changes or cancellations are required for the future contract."
                      : pendingPaymentMeta?.is_extend_requested
                      ? "Note: Please ask the sales executive to remove the extension request on Chakra, if applicable."
                      : ""}
                  </b>
                </p>

              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="orange_brd_button p-12-16-button mr-3"
            onClick={() => setOpenExitMOdal(false)}
          >
            CANCEL
          </button>
          <button
            type="button"
            className="basic-button "
            onClick={handleConfirmExitSubmitHandle}
          >
            MARK AS LEFT
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ExitTab;
