import React, { useEffect, useState, useContext } from "react";
import styles from "./index.module.scss";
import withDefaultDashBoardLayout from "../../../components/layouts/withDefaultDashBoardLayout";
import BreadCrumb from "../../../components/BreadCrumb";
import { revenueBulkFilters } from "./utils";
import { getBulkUploadEntriesApi } from "../../../helpers/services/revenueservices";
import moment from "moment";
import { Link } from "react-router-dom";
import UploadBulkModal from "./modals/UploadBulkModal";
import GlobalStateContext from "../../../GlobalStateContext";
import { getFiscalYearForListing } from "../../../helpers/common/common.function";
import Approval from "./modals/approveModal/Approval";
import { approveBulkUpload } from "../../../helpers/services/admin.services";
import toast from "react-hot-toast";
import DataTable from 'react-data-table-component';

const RevenueBulkUploadPage = () => {
  const { roles } = useContext(GlobalStateContext);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [activeItem, setActiveItem] = useState({
    label: "Bulk UTR Update",
    value: "utr_update",
    ctaLabel: "BULK UPDATE UTR",
    popup: {
      heading: "Bulk Update UTR",
      filePlaceHolder: "Upload PDC report",
      filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
    },
  });
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [sample_csv, setSampleCSV] = useState("");

  useEffect(() => {
    // if((roles?.admin || roles?.property_manager || roles?.warden || roles?.operations_head || roles?.cluster_manager ) &&  !(roles?.super_admin || roles?.finance_manager || roles?.finance_head || roles?.cfo)){
    if (roles?.super_admin || roles?.finance_head || roles?.cfo) {
      setActiveItem({
        label: "Bulk PDC Status Update",
        value: "utr_update",
        ctaLabel: "BULK UPDATE UTR",
        popup: {
          heading: "Bulk Update UTR",
          filePlaceHolder: "Upload PDC report",
          filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
        },
      });
    } else {
      setActiveItem({
        label: "Bulk UTR Update",
        value: "utr_update",
        ctaLabel: "BULK UPDATE UTR",
        popup: {
          heading: "Bulk Update UTR",
          filePlaceHolder: "Upload PDC report",
          filePlaceHolderDescription: "Upload CSV (Max 2 mb)",
        },
      });
    }
  }, [roles]);

  const handleSubmit = async (approval_method) => {
    try {
      const response = await approveBulkUpload(selectedId, approval_method);
      if (response.status == "Failure") {
        return toast.error(response.errors);
      } else {
        setSelectedId(null);
        toast.success(`${approval_method} successfully`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getEntries = async (type = "") => {
    let results = [];
    try {
      const res = await getBulkUploadEntriesApi(type);
      if (res) {
        setSampleCSV(res.sample_csv);
      }
      if (res.data) {
        results = res.data.map((item) => {
          let fileName = "";
          try {
            fileName = new URL(item.file_url || "").pathname
              .split("/")
              .pop()
              .split(".")[0];
              if (fileName.length > 62) {
                fileName = fileName.substring(0, 62) + "...";
              }
          } catch (e) {
            console.error(e);
          }
          return {
            ...item,
            fileName,
            dateOfUpload: moment(item.uploaded_on).format(
              "DD MMM YYYY hh:mm A"
            ),
            dateofApproval: item?.approver_action_on ? moment(item.approver_action_on).format(
              "DD MMM YYYY hh:mm A"
            ) : "--",
          };
        });
      }
    } catch (e) {
      console.log(e);
    }

    results.sort((a, b) => new Date(b.dateOfUpload) - new Date(a.dateOfUpload));
    setList(results);
  };

  useEffect(() => {
    setSelectedId(null);
    if (activeItem) {
      getEntries(activeItem.value);
    }
  }, [activeItem]);

  const onClose = (call = false) => {
    setShow(false);
    if (call) {
      getEntries(activeItem.value);
    }
  };

  const columns = [
    {
      name: 'Document',
      selector: (row) => (
        <div
      className="text-wrap"
      style={{
        overflowX: "hidden",
        wordWrap: "break-word",
        whiteSpace: "normal",
        overflowWrap: "break-word",
        maxWidth: "300px",
      }}
    >
      <Link
        className="cursor-pointer"
        to={row.file_url}
        target="_blank"
        rel="noopener"
      >
        {row?.fileName || "No File"}
      </Link>
    </div>
      ),      
      width: "18%",
      // sortable: true,
      // sortField: 'file_url',
    },
    {
      name: "Date of Upload",
      selector: (row) => row.dateOfUpload,
      // sortable: true,
      // sortField: "dateOfUpload",
      width: "13%",
    },
    {
      name: "Created By",
      selector: (row) => row?.created_by_employee?.employee_first_name ||
        row?.created_by_employee?.employee_last_name
          ? `${
              row?.created_by_employee?.employee_first_name ||
              ""
            } ${
              row?.created_by_employee?.employee_last_name ||
              ""
            }`
          : "--",
      // sortable: true,
      sortField: "created_by_employee",
      width: "12%",
    },
    {
      name: "Date of Approval",
      selector: (row) => row.dateofApproval,
      // sortable: true,
      // sortField: "dateofApproval",
      width: "15%",
    },
    {
      name: "Approved/Rejected By",
      selector: (row) => row?.aprover_action_employee?.employee_first_name ||
        row?.aprover_action_employee?.employee_last_name
          ? `${row?.aprover_action_employee?.employee_first_name || ""} ${row?.aprover_action_employee?.employee_last_name || ""}`
          : "--",
      // sortable: true,
      // sortField: "aprover_action_employee",
      width: "13%",
    },
    {
      name: "Status",
      selector: (row) => {
        
        const statusStyles = {
        completed: { backgroundColor: "rgba(121, 255, 108, .5)", color: "#247d58" },
        approved: { backgroundColor: "rgba(178, 243, 172, .5)", color: "#247d58" },
        initiated: { backgroundColor: "#FFEEDB", color: "#9a6600" },
        rejected: { backgroundColor: "#FFF3F3", color: "#de3730" },
      };
      
      return ( 
        <button
            style={{
          ...statusStyles[row.status], // Apply dynamic styles
          border: "none",
          borderRadius: "30px",
          padding: "2px 8px",
          fontWeight: "normal",
          textTransform: "capitalize",
          fontSize: "12px",
          textDecoration: "none",
          lineHeight: "16.8px"
          }}
            
          disabled={
            row?.status === "initiated" &&
            (roles.super_admin || roles?.finance_head || roles?.cfo)
              ? false
              : true
          }
          onClick={() => {
            if (roles.super_admin || roles?.finance_head || roles?.cfo) {
              setSelectedId(row?.id);
            }
          }}
        >
          {row?.status === "initiated"
            ? "Pending"
            : row?.status === "completed"
            ? "Processed"
            : row?.status}
        </button>
        )
      },
      // sortable: true,
      // sortField: "status",
    },
    {
      name: "Actions",
      selector: (row) => row.id,

      // sortable: true,
      width: "20%",
      sortField: "id",
      cell: (item) => (
        <td>
          {selectedId === item?.id && 
            (roles?.super_admin || roles?.finance_head || roles?.cfo) ? (
            <div className="d-flex align-items-center gap-8">
              {item?.status === "initiated" ? (
                <>
                  <button
                    className={"btn-approve"}
                    onClick={() => handleSubmit("approved")}
                  >
                    APPROVE
                  </button>
                  <button
                    className={"btn-reject"}
                    onClick={() => handleSubmit("rejected")}
                  >
                    REJECT
                  </button>
                  <img
                    src="/images/cross_modal.svg"
                    className="cursor-pointer ml-3"
                    alt="Close Icon"
                    onClick={() => setSelectedId(null)}
                  />
                </>
              ) : (
                <div className="mx-3">--</div>
              )}
            </div>
          ) : (
            <div className="mx-3 my-3">--</div>
          )}
        </td>
      )
    },
  ];

  return (
    <>
      <UploadBulkModal show={show} close={onClose} info={activeItem} />
      <div className={styles["revenue-bulk-upload"]}>
        <div className={styles["heading-container"]}>
          <div className={styles["heading-left"]}>
            <h1 className={"mb-0 " + styles["heading"]}>
              <span className={"font-zkga fw-800 text-gray-900"}>
                Bulk Uploads -{" "}
              </span>
              <span className={"font-manrope fw-700 text-gray-600"}>
                {getFiscalYearForListing()}
              </span>
            </h1>
            <div>
              <BreadCrumb
                seperator="/"
                list={[
                  {
                    label: "Revenue",
                    link: "",
                    active: false,
                  },
                  {
                    label: "Bulk Uploads",
                    link: "",
                    active: true,
                  },
                ]}
              />
            </div>
          </div>
        </div>

        <div className={styles["content-container"]}>
          <div className={"d-flex align-items-center gap-12"}>
            {revenueBulkFilters.map((bulkFilter) =>
              ((bulkFilter.value === "utr_update" ||
                bulkFilter.value === "refund_process") &&
                (roles?.admin || roles?.property_manager ||
                  roles?.warden ||
                  roles?.operations_head ||
                  roles?.cluster_manager) &&
                !(
                  roles?.super_admin ||
                  roles?.finance_manager ||
                  roles?.finance_head ||
                  roles?.cfo
                )) ||
              (bulkFilter.value === "wallet_recharge" &&
                !(
                  roles?.super_admin ||
                  roles?.finance_manager ||
                  roles?.finance_head ||
                  roles?.cfo
                )) ? null : (
                <div
                  onClick={() => {
                    setActiveItem(bulkFilter);
                  }}
                  className={
                    styles["tab-pane"] +
                    " " +
                    (activeItem?.value === bulkFilter.value
                      ? styles["active"]
                      : "")
                  }
                  key={"bulkFilter_" + bulkFilter.value}
                >
                  {bulkFilter.label}
                </div>
              )
            )}
          </div>
          <div className={styles["content-view-header"]}>
            <div className="d-flex align-items-center">
              <h4>Update History</h4>
              <div className="ms-auto d-flex align-items-center gap-12">
                {sample_csv && (
                  <a
                    className="cursor-pointer text-dark status-box"
                    href={sample_csv}
                    target="_blank"
                    rel="noopener"
                  >
                    <span
                      style={{
                        backgroundColor: "#fff4ed",
                        color: "#ff5700",
                        border: "1px solid #ff5700",
                        borderRadius: "100px",
                        fontWeight: 800,
                        fontSize: "14px",
                        padding: "16px 18px",
                      }}
                    >
                      SAMPLE CSV
                    </span>{" "}
                  </a>
                )}
                <button
                  className={"btn " + styles["btn-bulk"]}
                  onClick={() => {
                    setShow(true);
                  }}
                >
                  {activeItem?.ctaLabel}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sr_data_table mt-4">
        <DataTable
          columns={columns}
          data={list}
          pagination
          search
          fixedHeader={true}
          fixedHeaderScrollHeight={'415px'}
        />
      </div>
      <Approval
        show={showApprovalModal}
        setShow={setShowApprovalModal}
        id={selectedId}
        setSelectedId={setSelectedId}
      />
    </>
  );
};

export default withDefaultDashBoardLayout(RevenueBulkUploadPage);
